/* stylelint-disable at-rule-name-case */
/* stylelint-disable selector-class-pattern */
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.App-link {
  color: #61dafb;
}
img {
  border-style: none;
  vertical-align: middle;
}
#root {
  height: 100%;
}
#parent-area {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
.panel-logo {
  margin-bottom: 30px;
}
.select-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 45px;
  height: 64px;
  float: right;
  cursor: pointer;
}
.select-box:hover {
  background-color: #f5f5f5a5 !important;
}
.rightDropDown {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  float: right;
  cursor: pointer;
}
.rightDropDown:hover {
  background-color: #f5f5f5a5;
}
.content-warp-card {
  box-shadow: 0 1px 5px 0 rgba(51, 51, 51, 0.14);
  flex: 1;
  align-items: stretch;
}
.side-image {
  display: none;
}
@media screen and (min-width: 1100px) {
  .side-image {
    display: block;
    position: relative;
    width: 500px;
    border-right: 0.5px solid #c4cbd7;
  }
}
.forget-content {
  padding: 10px 100px 20px;
  margin: 30px auto;
  border: 2px solid #fff;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.login-panel {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  background-color: #ffffff;
  overflow: hidden;
}
.login-form {
  text-align: center;
  padding: 30px;
}
.login-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}
.loginBackground {
  flex: auto;
  display: flex;
  align-items: center;
  background: #fff no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}
.ant-menu-horizontal {
  border-bottom: none !important;
}
